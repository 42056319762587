import '../../style/index.less';
import './index.less';

// style dependencies
import '../../empty/style';
import '../../radio/style';
import '../../checkbox/style';
import '../../dropdown/style';
import '../../spin/style';
import '../../pagination/style';
